import React, { useEffect } from 'react';
import {
  Routes, Route, Navigate, useLocation, useNavigate, useParams,
} from 'react-router-dom';
// import login from 'Authentication/Infra/Presentation/views/login';
// import register from 'Authentication/Infra/Presentation/views/register';
// TODO: eliminar import userActivate from 'Authentication/Infra/Presentation/views/userActivate';
import EmailRecovery from 'Authentication/Infra/Presentation/views/emailRecovery';
import PasswordRecovery from 'Authentication/Infra/Presentation/views/passwordRecovery';
import SchoolLogin from 'Authentication/Infra/Presentation/views/school_login/school_login';
import SchoolRegister from 'Authentication/Infra/Presentation/views/school_register';
import PasswordReset from 'Authentication/Infra/Presentation/views/passwordReset';
/* import Vacantest from 'components/Vacantest'; */
// import Postulation from 'components/Postulation';
import NotFound from 'pages/NotFound';
import FamilyInfo from 'components/FamilyInfo';
/* import Faq from 'pages/Faq'; */
import { useUserData } from 'hooks/UserContext';
import { parsedDataInStorage } from 'utils/Storage/storageFunctions';
import api from 'services/api';
import AdvancedSearch from '../pages/AdvancedSearch';
import SchoolProfile from '../pages/SchoolProfile';
import Home from '../pages/Home';
// import Blogs from '../pages/Blog';
/* import HowItWorks from '../pages/HowItWorks'; */
import TermsAndConditions from '../pages/Terms&Conditions';
// import Contact from '../pages/Contact/index';
import PrivacyPolicies from '../pages/PrivacyPolicies';
import SchoolsByLocation from '../pages/SchoolsByLocation';
// import ExperienceCreator from '../pages/Experience/src/pages/experience-creator';
import ExperienceCreator from '../pages/Experience/src/pages/experience-creator/index_new';
import ExperienceRecommendations from '../pages/Experience/src/pages/recommendations';
import ExperienceCandadites from '../pages/Experience/src/pages/candidates';
import ExperienceFinisher from '../pages/Experience/src/pages/experience-finisher/index_new';
import AddSearchProfile from '../pages/Experience/src/pages/add-search-profile';
// import NewExpEmail from '../pages/Experience/src/pages/experience-email-new/view';
import loginSteps from '../components/LoginModal/src/steps';
import PrivateRoute from './privateRoute';
import paths from './paths';

const experiencePaths = [
  paths.NEW_PROFILE,
  paths.END_EXPERIENCE,
  paths.EXPERIENCE_CANDIDATES,
  paths.EXPERIENCE_RECOMMENDATIONS,
];

function RoutesComponent() {
  const { setUserData, setIsUserLoading } = useUserData();
  const location = useLocation();
  const navigate = useNavigate();

  const checkLoginStatus = async () => {
    const userInLocalStorage = parsedDataInStorage('vacanted');

    if (userInLocalStorage) {
      setIsUserLoading(true);
      setUserData({ loginStatus: true, data: userInLocalStorage });
    } else {
      setUserData({ loginStatus: false, data: {} });
    }

    const isLoggedInAsFamily = await api.checkFamilySessionIsValid();
    setIsUserLoading(false);
    if (!isLoggedInAsFamily
      && experiencePaths.some((path) => path === location.pathname)) {
      navigate('/');
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, []);

  function SchoolProfileWrapper() {
    const { id } = useParams();
    return <SchoolProfile id={id} />;
  }

  return (
    <Routes>
      <Route path="/" exact element={<Home />} />
      {/* <Route path="/email" exact element={<NewExpEmail />} /> */}
      <Route exact path="/user/activate" element={<Home defaultLoginStep={loginSteps.STEP_ACTIVATION} />} />
      <Route exact path="/user/password-reset" element={<Home defaultLoginStep={loginSteps.STEP_RECOVERY} />} />

      <Route path="/busqueda-interna-2024" element={<AdvancedSearch />} />
      <Route
        path="/school/:id"
        element={<SchoolProfileWrapper />}
      />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policies" element={<PrivacyPolicies />} />
      <Route exact path="/auth/login" element={<Home defaultLoginStep={loginSteps.STEP_USER_NAME} />} />
      <Route exact path="/auth/register" element={<Home defaultLoginStep={loginSteps.STEP_RECOVERY} />} />

      <Route path="/auth/school" element={<SchoolLogin />} />
      <Route path="/auth/school_register" element={<SchoolRegister />} />
      <Route path="/auth/email-recovery" element={<EmailRecovery />} />
      <Route path="/auth/password-recovery" element={<PasswordRecovery />} />
      <Route path="/auth/school-password-reset" element={<PasswordReset />} />
      <Route exact path="/vacantest" element={<Navigate to={paths.NEW_EXPERIENCE} replace />} />
      <Route
        path={paths.EXPERIENCE_RECOMMENDATIONS}
        element={<PrivateRoute Component={ExperienceRecommendations} />}
      />
      <Route
        path={paths.EXPERIENCE_CANDIDATES}
        element={<PrivateRoute Component={ExperienceCandadites} />}
      />
      <Route
        path={paths.NEW_PROFILE}
        element={<PrivateRoute Component={AddSearchProfile} />}
      />
      <Route exact path={paths.NEW_EXPERIENCE} element={<ExperienceCreator />} />
      <Route exact path={paths.END_EXPERIENCE} element={<ExperienceFinisher />} />
      <Route exact path="/family-infos" element={<FamilyInfo />} />
      <Route exact path="/colegios/:city/:neighborhood" element={<SchoolsByLocation />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default RoutesComponent;
